import React, {useLayoutEffect} from 'react'

const AppFooter = () => {

    return (
        <div className="app-footer">
            <div className="app-footer__inner">
                <div className="app-footer-left">
                <span>
                  copyright © and development {new Date().getFullYear()}{' '}
                    <a href="https://alternativascontables.pe" rel="noreferrer" target="_blank">
                    Alternativas Contables S.R.L.
                  </a>
                </span>
                </div>
                <div className="app-footer-right">
                    <span>v<strong>2.0.2003</strong></span>
                </div>
            </div>
        </div>
    )
}

export default AppFooter
